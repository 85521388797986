<template>
  <div class="container-fluid">
    <div class="row table-responsive">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" v-for="year in model.years" :key="year.year">
              {{ year.year }}
            </th>
            <th scope="col">Total</th>
            <th scope="col">CAGR %</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              {{ model.globalData.globalPercentages.label }}
            </th>
            <td
              v-for="element in model.globalData.globalPercentages.dataPoints"
              :key="element.year"
            >
              {{ element.value }}
            </td>
          </tr>

          <tr>
            <th scope="row">
              {{ model.globalData.globalRealValues.label }}
            </th>
            <td
              v-for="element in model.globalData.globalRealValues.dataPoints"
              :key="element.year"
            >
              {{ element.value }}
            </td>
            <td class="col-md-1 data-grid-calculated-element">
              {{ totalFor(model.globalData.globalRealValues.dataPoints) }}
            </td>
            <td class="col-md-1 data-grid-calculated-element">
              {{ cagrFor(model.globalData.globalRealValues.dataPoints) }}
            </td>
          </tr>

          <tr v-for="fusionElement in model.fusion" :key="fusionElement.label">
            <th scope="row">
              {{ fusionElement.label }}
            </th>
            <td v-for="element in fusionElement.dataPoints" :key="element.year">
              {{ element.value }}
            </td>
            <td class="col-md-1 data-grid-calculated-element">
              {{ totalFor(fusionElement.dataPoints) }}
            </td>
            <td class="col-md-1 data-grid-calculated-element">
              {{ cagrFor(fusionElement.dataPoints) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row" v-if="isTrends">
      <Accordion class="mt-2" style="padding: 0px">
        <AccordionTab
          :header="model.regionalPercentages.label + ' Percentages'"
        >
          <segmented-data
            :model="model.regionalPercentages"
            :readonly="!isTrends"
          ></segmented-data>
        </AccordionTab>
      </Accordion>
    </div>

    <div class="row">
      <Accordion class="mt-2" style="padding: 0px">
        <AccordionTab :header="model.regionalDataset.label">
          <segmented-data
            :model="model.regionalDataset"
            :readonly="!isTrends"
          ></segmented-data>
        </AccordionTab>
      </Accordion>
    </div>

    <div
      class="row"
      v-for="dataset in model.segmentationDatasets"
      :key="dataset.label"
    >
      <Accordion class="mt-2" v-if="isTrends" style="padding: 0px">
        <AccordionTab :header="dataset.label + ' Percentages'">
          <segmented-data
            :model="getSisterDataset(dataset)"
            :readonly="!isTrends"
          ></segmented-data>
        </AccordionTab>
      </Accordion>

      <Accordion class="mt-2" style="padding: 0px">
        <AccordionTab :header="dataset.label">
          <segmented-data
            :model="dataset"
            :readonly="!isTrends"
          ></segmented-data>
        </AccordionTab>
      </Accordion>
    </div>
  </div>
</template>
<script>
import SegmentedData from './SegmentedData/SegmentedData.vue';
import rowCalculationMethods from '@/shared/helpers/RowCalculations.js';
import rowValidationMethods from '@/shared/helpers/RowValidation.js';
import cellHelpers from '@/shared/helpers/CellHelpers.js';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
  // DataSetTabVM
  props: ['model'],
  mixins: [rowCalculationMethods, rowValidationMethods, cellHelpers],
  components: {
    SegmentedData,
    Accordion,
    AccordionTab,
  },
  data() {
    return {
      isExpandedGlyphClass: 'glyphicon-remove',
      isCollapsedGlyphClass: 'glyphicon-menu-hamburger',
    };
  },
  computed: {
    isTrends: function () {
      return this.model.datasetTypeId == 4; // trends id
    },
  },
  methods: {
    // defn : Takes a RV dataset, and finds the appropriate percentage
    // dataset. Returns it.
    getSisterDataset: function (dataset) {
      if (this.model.segmentationPercentages == undefined) {
        console.error('This model does not contain segmentation percentages');
        return;
      }

      return this.model.segmentationPercentages.find(
        (p) => p.label == dataset.label
      );
    },

    toggleDataset: function (dataset) {
      dataset.isOpen = !dataset.isOpen;
    },

    toggleSubrows: function (dataset) {
      dataset.isExpanded = !dataset.isExpanded;
    },
  },
};
</script>
