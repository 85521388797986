import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentFocus']),
  },

  methods: {
    ...mapMutations(['setCurrentlySelectedInput']),

    ...mapActions(['refreshFusion']),

    focusOnPoint: async function (datapoint, sisterRef) {
      datapoint.IsFocused = true;

      var suggestedValue = null;

      // Only try and get a suggested value if the datapoint hasn't got anything filled
      // in - otherwise it's not needed.
      // Likewise, only if tab is Trends.
      if (datapoint.Value == null && this.currentFocus.datasetTypeId == 4) {
        let suggestedValuePromise = this.getSuggestedValue(datapoint);
        suggestedValue = await suggestedValuePromise;
      }

      // this.$nextTick
      // https://vuejs.org/v2/api/#Vue-nextTick
      // Defer the callback to be executed after the next DOM update cycle.
      // Use it immediately after you’ve changed some data to wait for the DOM update.

      // We defer using next tick because we need to show the sister input before we
      // focus on it.
      this.$nextTick(() => {
        if (suggestedValue != null) {
          var attr = document.createAttribute('Placeholder');
          attr.value = suggestedValue;
          this.$refs[sisterRef][0].attributes.setNamedItem(attr);
        }

        this.$refs[sisterRef][0].focus();
        // Maintain the currentFocus on the store.
        this.setCurrentlySelectedInput(datapoint);
      });

      // We create the appropriate payload object as a parameter,
      // and send it across to mutate the store.
      this.refreshFusion({
        RegionalSegItemId: datapoint.RegionalSegItemId,
        ChildSegItemId: datapoint.ChildSegItemId,
      });
    },

    useSuggestedValue: async function (datapoint) {
      let suggestedValuePromise = this.getSuggestedValue(datapoint);
      let suggestedValue = await suggestedValuePromise;

      if (suggestedValue != null) {
        datapoint.Value = suggestedValue;
        this.submitValueChange(datapoint);
      }
    },

    unfocusPoint: function (datapoint) {
      datapoint.IsFocused = false;
      // Need to set currentFocus on the store to none.
    },

    moveCellUp: function (datapoint) {
      console.log('move cell up', datapoint);
    },

    moveCellDown: function (datapoint) {
      console.log('move cell down', datapoint);
    },

    formatPercentage: function (value) {
      if (value == null) return '';

      return value >= 0 ? '+' + value + '%' : value + '%';
    },

    getSuggestedValue: function (datapoint) {
      console.log(datapoint);
      // return new Promise((resolve, reject) => {
      //   // get a suggested value for that point.
      //   this.$http
      //     .get(
      //       globalVariables.baseUrl + 'MarketData/GetDatapointSuggestedValue',
      //       {
      //         params: {
      //           RegionalSegItemId: datapoint.RegionalSegItemId,
      //           ChildSegItemId: datapoint.ChildSegItemId,
      //           Year: datapoint.Year,
      //           IsPercentage: datapoint.IsPercentage,
      //         },
      //       }
      //     )
      //     .then(function (response) {
      //       resolve(response.body.SuggestedValue);
      //     });
      // });
    },
  },
};
