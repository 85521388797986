<template>
  <div v-for="row in model.rows" :key="row.label">
    <segmented-data-row
      :model="row"
      :readonly="readonly"
      :isExpanded="model.isExpanded"
    ></segmented-data-row>
  </div>
</template>
<script>
import SegmentedDataRow from './SegmentedDataRow.vue';

export default {
  name: 'segmented-data',

  props: [
    'model', // SegmentedDataVM
    'readonly',
  ],

  components: {
    SegmentedDataRow,
  },
};
</script>
