<template>
  <div>
    <div
      class="row data-set-row"
      v-bind:class="{ 'row-is-active': model.isFocused }"
    >
      <!-- v-on:click="selectRow" -->
      <div class="col-md-2 row-title" v-bind:class="'pl-' + model.depth">
        {{ model.label }}
      </div>
      <!-- <div class="col-md-10">
        <div
          class="col-md-1 data-grid-element"
          v-for="(dataPoint, index) in model.dataPoints"
          :key="dataPoint.dataSetPointId"
          :class="{ 'is-last-prediction': dataPoint.isFinalReference }"
        >
          <input
            type="text"
            v-show="!dataPoint.isFocused"
            class="form-control data-grid-form-control"
            :class="dataPoint.isValidCssClass"
            v-on:focus="focusOnPoint(dataPoint, 'index-' + index)"
            :placeholder="model.isPercentage ? '%' : ''"
            :value="
              model.isPercentage
                ? formatPercentage(dataPoint.value)
                : dataPoint.value
            "
            :readonly="readonly"
          />

          <input
            type="text"
            v-show="dataPoint.isFocused"
            class="form-control data-grid-form-control"
            :class="dataPoint.isValidCssClass"
            :ref="'index-' + index"
            v-model.number="dataPoint.value"
            v-on:change="submitValueChange(dataPoint)"
            v-on:blur="unfocusPoint(dataPoint)"
            v-on:keydown.tab="useSuggestedValue(dataPoint)"
            v-on:keydown.up="moveCellUp(dataPoint)"
            v-on:keydown.down="moveCellDown(dataPoint)"
            v-on:keydown="isNumber($event)"
            :readonly="readonly"
          />
        </div>
        <div class="col-md-1 data-grid-calculated-element">
          <span v-if="!model.isPercentage">
            {{ totalFor(model.dataPoints) }}
          </span>
        </div>
        <div
          class="col-md-1 data-grid-calculated-element"
          v-bind:class="{
            'cagr-based-on-incomplete-data': !isCagrBasedOnCompleteData(
              model.dataPoints
            ),
          }"
        >
          <span v-if="!model.isPercentage">
            {{ cagrFor(model.sataPoints) }}
          </span>
        </div>
      </div> -->
    </div>

    <!-- <div
      v-for="subrow in model.subRows"
      v-show="isExpanded"
      :key="subrow.label"
    >
      <segmented-data-row
        :model="subrow"
        :readonly="readonly"
        :isExpanded="false"
      >
      </segmented-data-row>
    </div> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import RowCalculationMethods from '@/shared/helpers/RowCalculations.js';
import RowValidationMethods from '@/shared/helpers/RowValidation.js';
import CellHelpers from '@/shared/helpers/CellHelpers.js';

export default {
  name: 'segmented-data-row',

  props: [
    'model', // DataRowVM
    'readonly',
    'isExpanded',
  ],

  mixins: [RowCalculationMethods, RowValidationMethods, CellHelpers],
  computed() {
    console.log(this.model);
  },
  methods: {
    ...mapMutations(['setTrends']),

    // We handle the submission of a dataPoint change here.
    submitValueChange(dataPoint) {
      console.log(dataPoint);
    },
  },
};
</script>
