

export default { 

    methods : {
        /// <summary>
        /// Checks if the event keycode is a number
        /// </summary>
        /// <param name="e">The event</param>
        isNumber: function (e) {

            // Allow: backspace, delete, tab, escape, enter and .
            // Place in common keys array & check if event keycode is in there
            var commonKeysArray = [46, 8, 9, 27, 13, 110, 190];

            if (
                commonKeysArray.includes(e.keyCode) ||
                // Allow: Ctrl/cmd+A
                (e.keyCode == 65 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+C
                (e.keyCode == 67 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+X
                (e.keyCode == 88 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: Ctrl/cmd+V (might want to copy things in there :
                //                    If they copy letters, let the server deal - it won't be saved)
                (e.keyCode == 86 && (e.ctrlKey === true || e.metaKey === true)) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39) || 
                // Allow: minus sign
                (e.keyCode == 173 || e.keyCode == 109)
            ) {
                // let it happen, don't do anything
                return;
            }

            // Ensure that it is a number and stop the keypress
            if (
                (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
                (e.keyCode < 96 || e.keyCode > 105)
            ) {
                e.preventDefault();
            }
        }
    }    

}