// A helper mixin that we use in row components. 
// We can then provide shared methods to calculate the cagr / total etc 
// for that row. 

export default {
    methods : {

        // defn : Method takes dataPoints (corresponding to returned 
        // DataPointVM from server) and calculates the cagr for the row. 
        // Returns it or an empty string. 
        cagrFor: function(dataPoints) {
            // https://en.wikipedia.org/wiki/Compound_annual_growth_rate
            
            // t0 is the initial prediction year (or first filled in)
            // tn is the final prediction year filled in
    
            // If an entry is blank, then we do not include that in our calculations
            // We need >= 2 entries to calculate a CAGR value
            // If all values are not filled in, then the CAGR value displayed needs to
            // have a warning background colour.
    
            // The Formula is as follows :
            // cagr(t0,tn) = (V(tn)/V(t0))^(1/(tn-t0)) - 1
    
            var predictionYearsArray = dataPoints.filter(
                dp => dp.IsPrediction
            ).map(dp => dp.Year);
    
            var VtiArray = dataPoints.filter(function(dp) {
                return (
                    predictionYearsArray.includes(dp.Year) && dp.Value // is a prediction year array
                ); // Value is truthy (ie : has been filled in)
            }).sort(function(a, b) {
                // Sort it by year
                return a.Year - b.Year;
            });
    
            var yearsWithValues = VtiArray.filter(function(y) {
                return y.Value;
            }).map(function(y) {
                return y.Year;
            });
    
            if (VtiArray.length > 1) {
                var tn = Math.max.apply(Math, yearsWithValues);
                var t0 = Math.min.apply(Math, yearsWithValues);
        
                var Vtn = VtiArray[VtiArray.length - 1].Value;
                var Vt0 = VtiArray[0].Value;
        
                var base = Vtn / Vt0;
                var exponent = 1 / (tn - t0);
        
                var result = Math.pow(base, exponent) - 1;
        
                var resultAsPercentage = (result * 100).toFixed(1) + "%";
        
                return resultAsPercentage;
            } else {
                return "";
            }
        },

        // defn : Method returning a boolean flag representing whether the
        // cagr for a set of datapoints has been calculated off complete 
        // data. 
        isCagrBasedOnCompleteData : function(dataPoints) {
            var predictionYears = dataPoints.filter(
                dp => dp.IsPrediction
            ).map(dp => dp.Year);
        
            return dataPoints.filter(function(dp) {
                return predictionYears.includes(dp.Year);
            }).every(function(dp) {
                return dp.Value; // are all values in row truthy
            });
        },

        // defn : Method takes dataPoints (corresponding to returned 
        // DataPointVM from server) and sums the values, returning the 
        // calculated total. 
        totalFor: function(dataPoints) {
            // We total the values (where entered) in the row
            var total = 0;
      
            dataPoints.forEach(function(dp) {
              if (dp.Value) {
                total += dp.Value;
              }
            });
            return Math.round( total * 10 ) / 10;
          },
    }
}