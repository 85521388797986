<template>
  <div v-if="!loading">
    <TabView>
      <TabPanel :header="model.requirementsTab.label">
        <Tab :model="model.requirementsTab.dataVM"></Tab>
      </TabPanel>
      <TabPanel :header="model.productionTab.label">
        <Tab :model="model.productionTab.dataVM"></Tab>
      </TabPanel>
      <TabPanel v-bind:header="model.trendsTab.label">
        <Tab :model="model.trendsTab.dataVM"></Tab>
      </TabPanel>
    </TabView>
  </div>

  <div v-else>Loading...</div>
</template>

<script>
import { mapMutations } from 'vuex';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tab from './Data/Tab.vue';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'MarketVolumeData',
  components: {
    Tab,
    TabView,
    TabPanel,
  },
  data() {
    return {
      loading: true,
    };
  },
  async mounted() {
    await this.getMarketData();
  },
  computed: {
    model() {
      return this.$store.getters.model;
    },
  },
  methods: {
    async getMarketData() {
      try {
        this.loading = true;
        await this.$store.dispatch('initModel');
        toast.success('Market Data obtained successfully.');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    ...mapMutations(['setCurrentlySelectedDataSetType']),
  },
};
</script>
